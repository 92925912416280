import React from 'react';
import styles from './EasyTable.module.css';

const EasyTable = ({ title, data }) => {
  return (
    <div className={styles.tableContainer}>
      <h3>{title}</h3>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter(row => row.comment_label === 'hard')
            .map((row, index) => (
              <tr key={index}>
                <td>{row.comment_text}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EasyTable;
