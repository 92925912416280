import React, { useState } from 'react';
import styles from './Main.module.css';
import { post } from 'aws-amplify/api';
import Header from './Header';
import EasyTable from './EasyTable';
import HardTable from './HardTable';

const Main = ({ onSignOut, user }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [difficulty, setDifficulty] = useState('easy'); // State for the toggle pill
  const [groupedComments, setGroupedComments] = useState([]); // State for grouped comments
  const [isLoading, setIsLoading] = useState(false);
  const [statusText, setStatusText] = useState('');

  const LoadingAnimation = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.loadingAnimation}></div>
    </div>
  );

  const handleResponse = (responseBody) => {
    const grouped = responseBody.map(video => {
      const { video_name, comments } = video;
      const parsedComments = JSON.parse(comments).map(comment => ({
        comment_text: comment.comment_text,
        comment_label: comment.comment_label,
        comment_reply: comment.comment_reply,
      }));
      return {
        video_name,
        comments: parsedComments
      };
    });
    console.log(grouped);
    setGroupedComments(grouped);
  };

  const getComments = async () => {
    let apiResponse;
    let responseBody;
    setIsLoading(true);
    setStatusText('Loading comments...');

    try {
      const getCall = await post({
        apiName: 'may15GetAiReplies',
        path: '/replies',
        options: {
          body: {
            "user": user,
            "startDate": startDate,
            "endDate": endDate,
          },
        },
      });

      apiResponse = await getCall.response;
      responseBody = await apiResponse.body.json();

    } catch (err) {
      console.log('Error while processing request');
      console.log(err);
    } finally {
      if (responseBody) {
        // console.log('API Response Body:', responseBody);
        handleResponse(responseBody);
        setIsLoading(false);
      }
    }
  };

  const handleEnter = () => {
    if (startDate === '' || endDate === '') {
      alert('Please enter both Start Date and End Date');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (end < start) {
      alert('Please make sure that the End Date is after the Start Date');
      return;
    }

    getComments();
  };

  const toggleDifficulty = () => {
    setDifficulty(difficulty === 'easy' ? 'hard' : 'easy');
  };

  return (
    <div className={styles.mainContainer}>
      <Header onSignOut={onSignOut} user={user} />
      <div className={styles.centeredContent}>
        <label className={styles.dateLabel}>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label className={styles.dateLabel}>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={handleEnter}>Enter</button>
        <div className={styles.toggleContainer} onClick={toggleDifficulty}>
          <div className={`${styles.toggleText} ${difficulty === 'easy' ? '' : styles.hidden}`}>Easy</div>
          <div className={`${styles.toggleText} ${difficulty === 'hard' ? '' : styles.hidden}`}>Hard</div>
          <div className={styles.toggleSwitch} style={{ transform: difficulty === 'easy' ? 'translateX(0)' : 'translateX(100%)' }} />
        </div>
      </div>
      {isLoading && <LoadingAnimation />}
      {isLoading && <div className={styles.loadingText}>{statusText}</div>}
      <div className={styles.tablesContainer}>
        {difficulty === 'easy' &&
          groupedComments.map((group, index) => (
            <EasyTable key={index} title={group.video_name} data={group.comments} />
          ))
        }
        {difficulty === 'hard' &&
          groupedComments.map((group, index) => (
            <HardTable key={index} title={group.video_name} data={group.comments} />
          ))
        }
      </div>
    </div>
  );
};

export default Main;
