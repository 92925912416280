import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Login';
import Comments from './Components/Comments';
import { post } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

// Components

// toast.configure();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };

  const checkAuthenticated = async () => {
    try {
      // Assuming you have an endpoint to verify the JWT token
      const restOperation = await post({
        apiName: 'may15HandleVerify',
        path: '/auth/verify',
        options: {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('orangeToken')}`
          }
        }
      });
      const apiResponse = await restOperation.response;
      const responseBody = await apiResponse.body.json();
      console.log("API Response Body:", responseBody);
      setIsAuthenticated(responseBody === true);
    } catch (err) {
      console.error("err: ", err.message);
      setIsAuthenticated(false);
    } finally {
      setLoading(false); // Set loading to false after check
    }
  };

  useEffect(() => {
    console.log("Checking authentication...");
    checkAuthenticated();
  }, []);

  useEffect(() => {
    console.log("isAuthenticated:", isAuthenticated);
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while checking authentication
  }

  return (
    <>
      <Router>
        <div className="container">
          <Routes>
            <Route path="/login" element={!isAuthenticated ? <Login setAuth={setAuth} /> : <Navigate replace to="/comments" />} />
            <Route path="/comments" element={isAuthenticated ? <Comments setAuth={setAuth} /> : <Navigate replace to="/login" />} />
            {/* Redirect all other paths to "/login" or a NotFound component */}
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
